import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import { ToolTipWrapper } from "../../../Cargo/Controls/QuestionBubble";
import CreditCardLogo from "../../../Cargo/Icons/CreditCardLogo";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import FontWeights from "../../../Cargo/Text/FontWeights";
import SmallFromTo from "../../Dashboard/Components/SmallFromTo";
import {
  Currency,
  Invoice,
  Shipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { InvoiceState } from "@freightsimple/generated-dashboard-openapi-client";
import { InvoiceType } from "@freightsimple/generated-dashboard-openapi-client";
import { formatPostalCode } from "../../../Helpers/formatPostalCode";
import { isOverdue } from "../../../Helpers/isOverdue";
import moment from "moment";
import { ReactNode } from "react";
import styled from "styled-components";

const ChargeDescription = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 16px;
  color: #545454;
`;
const ChargeDate = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 12px;
  color: #858585;
`;
const PriceText = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 18px;
  color: #545454;
`;
const CurrencyText = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 18px;
  color: var(--freightsimple-color-light-text);
`;

interface InvoiceRowBodyProps {
  highlightBorder: boolean | undefined;
}

const InvoiceRowBody = styled.div<InvoiceRowBodyProps>`
  background-color: #f7f7f7;
  width: 1320px;
  padding: 8px 12px;
  border-radius: 16px;
  margin-bottom: 16px;

  &:hover {
    background-color: #f0f0f0;
  }

  ${(props) =>
    props.highlightBorder
      ? `border:1px solid ${Colors.LightBlue};`
      : `border:1px solid white;`}
`;

interface InvoiceRowStatusProps {
  invoice: Invoice;
}

const InvoiceDueDate = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 14px;
`;

function invoiceRowBackgroundColor(invoice: Invoice) {
  const overdue = isOverdue(invoice.dueDate);
  const failed = invoice.lastPaymentAttemptFailed;
  const isRefund =
    invoice.invoiceType === InvoiceType.AdditionalChargeRefund ||
    invoice.invoiceType === InvoiceType.ShipmentRefund;

  if (invoice.invoiceState === InvoiceState.Settled) {
    return isRefund ? "black" : Colors.DarkGreen;
  }

  if (invoice.invoiceState === InvoiceState.SettlementPending) {
    return Colors.LightBlue;
  }

  if (invoice.invoiceState === InvoiceState.Voided) {
    return "black";
  }

  if (invoice.invoiceState === InvoiceState.Issued) {
    return overdue || failed ? Colors.Red : Colors.Gold;
  }

  // should not be here
  throw new Error("Invalid invoice state");
}

function invoiceRowStatusWeight(invoice: Invoice) {
  if (invoice.invoiceState === InvoiceState.Issued) {
    return FontWeights.Medium;
  }

  return FontWeights.Regular;
}

function invoiceRowColor(invoice: Invoice) {
  const overdue = isOverdue(invoice.dueDate);

  if (invoice.invoiceState === InvoiceState.Settled) {
    return Colors.White;
  }

  if (invoice.invoiceState === InvoiceState.SettlementPending) {
    return Colors.White;
  }

  if (invoice.invoiceState === InvoiceState.Voided) {
    return Colors.White;
  }

  if (invoice.invoiceState === InvoiceState.Issued) {
    return overdue ? Colors.White : Colors.NormalText;
  }

  // should not be here
  throw new Error("Invalid invoice state");
}

const InvoiceRowStatus = styled.div<InvoiceRowStatusProps>`
  text-align: center;
  font-weight: ${(props) => invoiceRowStatusWeight(props.invoice)};
  font-size: 13px;
  color: ${(props) => invoiceRowColor(props.invoice)};
  background-color: ${(props) => invoiceRowBackgroundColor(props.invoice)};
  border-radius: 6px;
  padding: 2px 8px;
  user-select: none;
`;

function invoiceStatusDescription(invoice: Invoice) {
  const invoiceState = invoice.invoiceState;

  const overdue = isOverdue(invoice.dueDate);

  const isRefund =
    invoice.invoiceType === InvoiceType.AdditionalChargeRefund ||
    invoice.invoiceType === InvoiceType.ShipmentRefund;

  switch (invoiceState) {
    case InvoiceState.Issued:
      return invoice.lastPaymentAttemptFailed
        ? "Failed"
        : overdue
          ? "Overdue"
          : "Unpaid";
    case InvoiceState.Voided:
      return "Voided";
    case InvoiceState.SettlementPending:
      return "Pending";
    case InvoiceState.Settled:
      return isRefund ? "Refund" : "Paid";
  }
}

function invoiceDescription(invoiceType: InvoiceType): string {
  switch (invoiceType) {
    case InvoiceType.ShipmentCharge:
      return "Shipment Charge";
    case InvoiceType.AdditionalCharge:
      return "Additional Charge";
    case InvoiceType.ShipmentRefund:
      return "Shipment Charge";
    case InvoiceType.AdditionalChargeRefund:
      return "Shipment Charge";
  }
}

interface InvoiceStatusTooltipWrapperProps {
  invoice: Invoice;
  children: ReactNode;
}

function InvoiceStatusTooltipWrapper(props: InvoiceStatusTooltipWrapperProps) {
  if (props.invoice.invoiceState !== InvoiceState.SettlementPending) {
    return <>{props.children}</>;
  }

  return (
    <ToolTipWrapper
      description={
        <Stack align="left">
          <p>Bank account debits take a number of days to confirm.</p>
          <Spacer height={4} />
          <p>
            When the transfer is confirmed you will see the invoice as 'Paid'.
          </p>
        </Stack>
      }
    >
      {props.children}
    </ToolTipWrapper>
  );
}

interface InvoiceRowProps {
  invoice: Invoice;
  shipment: Shipment;
  onViewShipment: () => void;
  onDownloadInvoice: () => void;
  highlightBorder?: boolean;
  downloadButtonNonPrimary?: boolean;
}
function InvoiceRow(props: InvoiceRowProps) {
  const { invoice: invoice, shipment } = props;

  const invoiceType = invoice.invoiceType;

  const {
    pickupReferenceNumber,
    deliveryReferenceNumber,
    pickupLocation,
    deliveryLocation,
  } = shipment;
  const pickupCity = `${pickupLocation.address.city}, ${pickupLocation.address.stateOrProvinceCode}`;
  const deliveryCity = `${deliveryLocation.address.city}, ${deliveryLocation.address.stateOrProvinceCode}`;

  // If we don't have a business name, use the postal code
  const pickupBusinessName =
    pickupLocation.businessName ??
    (pickupLocation.address.postalCode
      ? `Picking up from : ${formatPostalCode(
          pickupLocation.address.postalCode,
        )}`
      : "");
  const deliveryBusinessName =
    deliveryLocation.businessName ??
    (deliveryLocation.address.postalCode
      ? `Delivering to : ${formatPostalCode(
          deliveryLocation.address.postalCode,
        )}`
      : "");

  let amountPrefix = "";
  if (
    invoiceType === InvoiceType.ShipmentRefund ||
    invoiceType === InvoiceType.AdditionalChargeRefund
  ) {
    amountPrefix = "-";
  }

  function formatIdentifierDateLine(invoice: Invoice) {
    if (!invoice.invoiceIdentifier) {
      return moment(invoice.invoiceDate).format("Do MMMM YYYY");
    }

    return `${invoice.invoiceIdentifier} - ${moment(invoice.invoiceDate).format(
      "Do MMMM YYYY",
    )}`;
  }

  return (
    <InvoiceRowBody highlightBorder={props.highlightBorder}>
      <HorizontalStack verticalAlign="middle">
        <Spacer width={8} />

        <div style={{ width: "200px" }}>
          <Stack align="left">
            <ChargeDescription>
              {invoiceDescription(invoice.invoiceType)}
            </ChargeDescription>
            <ChargeDate>{formatIdentifierDateLine(invoice)}</ChargeDate>
          </Stack>
        </div>
        <Spacer width={8} />
        <div style={{ width: "105px" }}>
          <Stack align="left">
            <InvoiceStatusTooltipWrapper invoice={invoice}>
              <InvoiceRowStatus invoice={invoice}>
                <Stack>
                  <>{invoiceStatusDescription(invoice)}</>
                  {invoice.invoiceState === InvoiceState.Issued && (
                    <InvoiceDueDate>
                      Due: {moment(invoice.dueDate).format("Do MMM")}
                    </InvoiceDueDate>
                  )}
                </Stack>
              </InvoiceRowStatus>
            </InvoiceStatusTooltipWrapper>
          </Stack>
        </div>
        <Spacer width={16} />

        <SmallFromTo
          pickupBusinessName={pickupBusinessName}
          pickupCity={pickupCity}
          pickupReference={pickupReferenceNumber}
          deliveryBusinessName={deliveryBusinessName}
          deliveryCity={deliveryCity}
          deliveryReference={deliveryReferenceNumber}
        />

        <div style={{ width: "120px" }}>
          <HorizontalStack style={{ width: "120px" }} align="right">
            <PriceText>
              ${amountPrefix}
              {invoice.amount.toFixed(2)}
            </PriceText>
            <Spacer width={4} />
            <CurrencyText>
              {invoice.currency === Currency.Cad ? "CAD" : "USD"}
            </CurrencyText>
          </HorizontalStack>
        </div>
        <Spacer width={16} />
        <div style={{ width: "30px" }}>
          <HorizontalStack
            style={{
              position: "relative",
              top: "2px",
              marginRight: "12px",
            }}
          >
            {invoice.brand && (
              <CreditCardLogo
                size="small"
                brand={invoice.brand}
                paymentMethod={invoice.paymentMethod}
              />
            )}
          </HorizontalStack>
        </div>
        <Spacer width={16} />
        <HorizontalStack>
          <Button secondary onClick={props.onViewShipment}>
            View Shipment
          </Button>
          <Spacer width={8} />
          <Button
            secondary={props.downloadButtonNonPrimary}
            icon="file-pdf"
            onClick={props.onDownloadInvoice}
          >
            Download
          </Button>
        </HorizontalStack>
      </HorizontalStack>
    </InvoiceRowBody>
  );
}
export default InvoiceRow;
